import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    render() {

        const { isMenuShow } = this.state;

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
                    <div className="logo2">
                        <h2><Link to='/' onClick={this.menuHandler}>Lída & Honza</Link></h2>
                    </div>
                    <ul className="responsivemenu">
                        <li><AnchorLink onClick={this.menuHandler} href='#header'>Domů</AnchorLink></li>
                        <li><AnchorLink onClick={this.menuHandler} href='#couple'>O nás</AnchorLink></li>
                        <li><AnchorLink onClick={this.menuHandler} href='#getting'>Cesta a ubytování</AnchorLink></li>
                        <li><AnchorLink onClick={this.menuHandler} href='#event'>Harmonogram</AnchorLink></li>
                        <li><AnchorLink onClick={this.menuHandler} href='#rules'>Pravidla</AnchorLink></li>
                        <li><AnchorLink onClick={this.menuHandler} href='#gallery'>Galerie</AnchorLink></li>
                    </ul>

                </div>

                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}
