import React from  'react';
import Sectiontitle from '../section-title'
import './style.css'

const Rules = () => {
    return(
        <div id="rules" className="rules-area section-padding">
            <Sectiontitle section={'Pravidla a tipy'}/>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Dresscode</h4>
                                <div className="rules-content">
                                    <p>Svatba je společenskou událostí, proto tomu, prosím, přizpůsobte i svůj oděv. Pokud se s námi budete veselit do pozdních večerních hodin, stále se účastníte formální společenské akce. Je v pořádku odhodit kravatu nebo vyměnit podpatky za obuv vhodnou k tanci, ale <strong>dodržujte formální dresscode po celou dobu svatby</strong>. Toto pravidlo samozřejmě neplatí pro dětské návštěvníky svatby, kteří den stráví na místním pískovišti. Dále připomínáme všem známé – bílá barva je výsadou nevěsty.</p>
                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Svatební dary</h4>
                                <div className="rules-content">
                                    <p>Se svatebními dary si, prosíme, nedělejte starosti. Důležité je, že přijdete. Pokud byste nás ale přece jen chtěli obdarovat, budeme rádi za finanční příspěvek na svatební cestu.</p>
                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Speciální prosba nevěsty (i ženicha)</h4>
                                <div className="rules-content">
                                    <p>Vážení hosté, debaty o politice patří do nedělních Otázek, proto Vás moc prosím, ponechme alespoň na jeden den stranou diskuze o světovém dění a své politické preference proberte spíše nad pátečním pivem <span role="img" aria-label="smiling face">{`\u{263a}`}</span>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rules;