import React from  'react';

// components
import Couple from '../../components/couple';
import SimpleSlider from '../../components/hero';
//import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import Schedule from '../../components/location';
import Gallery from '../../components/gallery';
import Getting from '../../components/getting';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer'

import couple1 from '../../images/couple/img-2.jpg'
import couple2 from '../../images/couple/img-1.jpg'
import Rules from '../../components/rules';


const Homepage = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider/>
           <Saveday/>
           <Couple coupleimg1={couple1} coupleimg2={couple2}/>
           <Welcome/>
           <Getting/>
           <Schedule/>
           <Rules/>
           <Gallery/>
           <Footer />
       </div>
    )
}

export default Homepage;