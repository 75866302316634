import React, {Component} from 'react';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Sectiontitle from '../section-title'
import './style.css'

import galimg1 from '../../images/gal/galerie_01.jpg'
import galimg2 from '../../images/gal/galerie_02.jpg'
import galimg3 from '../../images/gal/galerie_03.jpg'
import galimg4 from '../../images/gal/galerie_04.jpg'
import galimg5 from '../../images/gal/galerie_05.jpg'
import galimg6 from '../../images/gal/galerie_06.jpg'
import galimg7 from '../../images/gal/galerie_07.jpg'
import galimg8 from '../../images/gal/galerie_08.jpg'
import galimg9 from '../../images/gal/galerie_09.jpg'
import galimg10 from '../../images/gal/galerie_10.jpg'
import galimg11 from '../../images/gal/galerie_11.jpg'
import galimg12 from '../../images/gal/galerie_12.jpg'
import galimg13 from '../../images/gal/galerie_13.jpg'
import galimg14 from '../../images/gal/galerie_14.jpg'
import galimg15 from '../../images/gal/galerie_15.jpg'
import galimg16 from '../../images/gal/galerie_16.jpg'
import galimg17 from '../../images/gal/galerie_17.jpg'
import galimg18 from '../../images/gal/galerie_18.jpg'
import galimg19 from '../../images/gal/galerie_19.jpg'
import galimg20 from '../../images/gal/galerie_20.jpg'
import galimg21 from '../../images/gal/galerie_21.jpg'


class Gallery extends Component {
  render() {
    return (
      <div id="gallery" className="Gallery-section section-padding">
          <Sectiontitle section={'Galerie'}/>
          <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg1}
                            image={galimg1}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg2}
                            image={galimg2}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg3}
                            image={galimg3}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg4}
                            image={galimg4}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg5}
                            image={galimg5}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg6}
                            image={galimg6}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg7}
                            image={galimg7}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg8}
                            image={galimg8}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg9}
                            image={galimg9}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg10}
                            image={galimg10}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg11}
                            image={galimg11}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg12}
                            image={galimg12}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg13}
                            image={galimg13}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg14}
                            image={galimg14}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg15}
                            image={galimg15}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg16}
                            image={galimg16}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg17}
                            image={galimg17}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg18}
                            image={galimg18}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg19}
                            image={galimg19}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg20}
                            image={galimg20}/>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="gallery-img">
                        <ReactFancyBox
                            thumbnail={galimg21}
                            image={galimg21}/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Gallery;