import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import prijezdImg from '../../images/gta/prijezd-a-parkovani.jpg'
import ubytovaniImg from '../../images/gta/ubytovani-1.jpg'
import Sectiontitle from '../section-title'
import './style.css'



const Getting = (props) => {
    const {
        className
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <div id="getting" className="gta-area section-padding">
            <Sectiontitle section={'Cesta a Ubytování'} />
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Cesta a parkování</h4>
                                <div className="gta-content">
                                    <p>Abyste se pohodlně dostali na místo svatby, využijte uvedenou mapu. Příjezd je možný přes Kašperské Hory nebo přes Rejštejn.</p>
                                    <p><strong>Závěrečná část cesty k rezidenci má horší podmínky. Prosíme, jeďte velmi opatrně! Cesta je obousměrná, ale vejde se na ni prakticky jen jedno auto. Proto tomu přizpůsobte jízdu. Z rezidence budou odjíždět pendlující “hosté” a můžete někoho na cestě potkat.</strong></p>
                                    <p>K parkování využijte přilehlé prostory. Je možné parkovat např. kousek nad vstupem do areálu. Prosíme Vás, abyste parkovaní přímo před branou do Vogelsangu nechali volné (3 až 4 místa) pro fotografku, kosmetičku a kapelu.</p>
                                    <p>Prosíme, abyste Váš příjezd přizpůsobili tomu, že je nutné se ohlásit na recepci a projit klasickými hotelovými ceremoniemi (občanky s sebou). Doporučujeme tak dorazit cca hodinu předem. Na recepci se nahlaste svým jménem, nasměrují Vás do Vašeho pokoje.</p>
                                </div>
                                <Button className="gta-btn" onClick={toggle}>Mapa</Button>
                                <Modal isOpen={modal} toggle={toggle} className={className}>
                                    <ModalHeader toggle={toggle}>Mapa</ModalHeader>
                                    <ModalBody>
                                        <div className="location-map">
                                            <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2611.616204729456!2d13.550260916549034!3d49.1129311793133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4774d10de8491ef7%3A0x3795b65efa0ad2c8!2sRezidence%20Vogelsang!5e0!3m2!1sen!2scz!4v1650819606428!5m2!1sen!2scz" />
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div className="gta-img">
                                    <img src={prijezdImg} alt="" />
                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Ubytování</h4>
                                <div className="gta-content">
                                    <p>Všichni naši hosté mají zajištěné ubytovaní, které se nachází přímo v areálu, není tedy třeba někam přejíždět.</p>
                                    <p>Pokoje mají veškeré vybavení, včetně ručníku a ložního prádla. Dámám ubytovaným v prázdninovém domě (většina přátel) doporučujeme vybavit se zrcátky, jelikož jsou koupelny společné pro tři pokoje. </p>
                                    <p>Prosíme kuřáky, aby respektovali fakt, že se rezidence nachází v přírodě a na svatbě jsou přítomní i dětští návštěvnici. Kuřte tak jen v místech tomu určených.</p>
                                    <p>PS: Vaše uspořádání v pokojích nic nevypovídá o Vaší oblíbenosti. Snažili jsme se pouze o respektování potřeb jednotlivců, zejména těch s dětmi <span role="img" aria-label="smiling face">{`\u{263a}`}</span>.</p>
                                </div>
                                <div className="gta-img">
                                    <img src={ubytovaniImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;