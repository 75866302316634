import React from 'react';
import './style.css'

const Welcome = () => {

  return (

    <div className="welcome-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="welcome-content">
                        <h2>Zveme vás na náš velký den</h2>
                        <p>Srdečně Vás zveme na náš velký den. Věříme, že si ho spolu užijeme a vytvoříme si spoustu krásných vzpomínek.</p>
                        <p>Na webu najdete pár informací užitečných při přípravě i v průběhu události.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Welcome;