import CountDown from 'reactjs-countdown';

class CzechCountDown extends CountDown {
    setDayString() {
        if (this.days() === 0) {
            return "dnů";
        } else if (this.days() === 1) {
            return "den";
        } else if (this.days() < 5) {
            return "dny";
        } else {
            return "dní";
        }
    }

    setHourString() {
        if (this.hours() === 0) {
            return "hodin";
        } else if (this.days() === 1) {
            return "hodina";
        } else if (this.days() < 5) {
            return "hodiny";
        } else {
            return "hodin";
        }
    }
}

export default CzechCountDown;