
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Sectiontitle from '../section-title'
import prijezdImg from '../../images/events/prijezd.jpg'
import obradImg from '../../images/events/svatebni-obrad.jpg'
import obedImg from '../../images/events/svatebni-obed.jpg'
import tanecImg from '../../images/events/prvni-tanec.jpg'
import dortImg from '../../images/events/krajeni-dortu.jpg'
import vecerniZabavaImg from '../../images/events/vecerni-zabava.jpg'
import foceniImg from '../../images/events/foceni-s-novomanzeli.jpg'
import snidaneImg from '../../images/events/snidane.jpg'
import volnaZabavaImg from '../../images/events/volna-zabava.jpg'

import './style.css'

const Schedule = (props) => {

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={'Harmonogram'} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={prijezdImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Příjezd svatebčanů a ubytování</h3>
                                                    <span>11:00 až 12:30</span>
                                                    <span>Rezidence Vogelsang</span>
                                                    <p>Příjezd si naplánujte s dostatečným předstihem, abyste se stihli před obřadem ubytovat a upravit. Nahlaste na recepci, kde Vás nasměrují do Vašeho pokoje.</p>
                                                    <Button className="btn-bot-margin" onClick={toggle}>Mapa</Button>
                                                    <Modal isOpen={modal} toggle={toggle} className={className}>
                                                        <ModalHeader toggle={toggle}>Mapa</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe title='Mapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2611.616204729456!2d13.550260916549034!3d49.1129311793133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4774d10de8491ef7%3A0x3795b65efa0ad2c8!2sRezidence%20Vogelsang!5e0!3m2!1sen!2scz!4v1650819606428!5m2!1sen!2scz" />
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Svatební obřad</h3>
                                                    <span>13:00 až 13:30</span>
                                                    <span>Rezidence Vogelsang, amfiteátr</span>
                                                    <p>Obřad se uskuteční v místním amfiteátru. Prosíme, ponechte přistavené židle volné pro naše rodiče.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={obradImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={obedImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Slavnostní oběd</h3>
                                                    <span>13:45 až 14:30</span>
                                                    <span>Rezidence Vogelsang, před restaurací</span>
                                                    <p>Pokud máte jakékoliv omezení (vegetariánství, intolerance), prosíme o včasné oznámení.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Krájení svatebního dortu</h3>
                                                    <span>15:00</span>
                                                    <span>Rezidence Vogelsang, před restaurací</span>
                                                    <p>Krájení dortu proběhne venku před restaurací.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={dortImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={foceniImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Focení s novomanželi</h3>
                                                    <span>17:00 až 17:45</span>
                                                    <span>Rezidence Vogelsang</span>
                                                    <p>Focení proběhne po skupinkách, které bude svolávat jedna z družiček, nebo nevěsta - buďte na pozoru :-).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Kapela a večerní zábava</h3>
                                                    <span>od 18:00</span>
                                                    <span>Rezidence Vogelsang</span>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={vecerniZabavaImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={volnaZabavaImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Druhé focení s novomanželi</h3>
                                                    <span>19:00 až 19:30</span>
                                                    <span>Rezidence Vogelsang</span>
                                                    <p>Focení proběhne po skupinkách, které bude svolávat jedna z družiček, nebo nevěsta - buďte na pozoru :-).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>První tanec novomanželů</h3>
                                                    <span>20:00</span>
                                                    <span>Rezidence Vogelsang, venku (za deště parket)</span>
                                                    <p></p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={tanecImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={snidaneImg} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Odhlášení z ubytování a snídaně</h3>
                                                    <span>9:00 až 12:00, 28. 8. 2022</span>
                                                    <span>Rezidence Vogelsang, recepce a restaurace</span>
                                                    <p>Odhlášení z ubytovaní druhý den má proběhnout do 10:00. Pro všechny naše hosty bude přichystaná snídaně. Doporučujeme se nejprve odhlásit na recepci a až následně dorazit na snídani, která bude probíhat až do oběda.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Schedule;