import React, { Component } from "react";
import AllRoute from '../router'
import './App.css';

import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

class App extends Component {

  render() {

    return (
      <Protect
//        sha512='EE26B0DD4AF7E749AA1A8EE3C10AE9923F618980772E473F8819A5D4940E0DB27AC185F8A0E1D5F84F88BC887FD67B143732C304CC5FA9AD8E6F57F50028A8FF'
        sha512='b6bb4fc087b519bd4d21562e0ded7b48354d34c927ff29d72baba38c26b2ff7051cb7ed155851985346d0be934f74c6990dd5a3b15fb51da89551f1c5e941fc0'
        blur={true}
        boxTitle='Obsah je chráněn heslem'
        inputPlaceholder='Heslo'
        buttonLabel='Vstoupit'
        styles={{
          input: {
            padding: '5px 5px',
          },
          header: {
            fontSize: '20px',
            padding: '10px 20px',
            backgroundColor: '#8c002aff',
            borderTopRightRadius: '7px',
            borderTopLeftRadius: '7px'
          },
          button: {
            backgroundColor: '#8c002aff',
            color: 'white',
            transitionDuration: '0.4s',
            borderRadius: '8px',
            padding: '7px 15px',
            border: '0px',
            "&:hover": {
              background: '#4CAF50'
            }
          },
          wrapper: {
            color: '#8c002aff',
            borderRadius: '10px',
            border: "2px solid",
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
          }
        }}
      >
        <div className="App">
          <AllRoute />
        </div>
      </Protect>
    );
  }
}

export default App;
